.login-container{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.login-text{
    display: flex;
    justify-content: center;
    margin-bottom: 36px;
    margin-top: 20px;
}