.cart-container {
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  /* FIXME: Change to media query */
}
.cart-products {
  width: 70%;
}
.cart-pay {
  width: 30%;
  margin-left: 50px;
}
.cart-product-link {
  color: #111111;
  font-size: 16px;
  text-decoration: none;
  /* margin-left: 16px; */
  white-space: nowrap;
  font-weight: 800;
  font-size: 18px;
}
.cart-product-link:hover {
  color: #004bff;
}
.cart-product-item {
  padding-top: 50px !important;
}
.cart-apply-coupon {
  margin: 0px 0px 0px 0px;
  padding: 30px 30px 30px 30px;
  background-color: #f7f7f7;
  border-radius: 10px 10px 10px 10px;
}
.cart-apply-coupon-text {
  display: block;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #d9d9d9;
  margin: 0px 0px 20px 0px !important;
  padding: 0px 0px 18px 0px !important;
}
.cart-apply-coupon-button {
  width: 100%;
  margin-top: 10px;
  height: 46px;
}
.cart-apply-coupon-button:hover {
  background-color: #004bff;
  border-color: #004bff;
}
.cart-total {
  margin-top: 25px;
  padding: 30px 30px 30px 30px;
  background-color: #f7f7f7;
  border-radius: 10px 10px 10px 10px;
}
.cart-table td,
.cart-table th {
  border: 0;
  background-color: #f7f7f7;
}
.cart-table-description {
  color: #323846ba !important;
}
.cart-total-button {
  background-color: #09a906 !important;
  border-color: #09a906 !important;
  width: 100%;
  height: 46px;
}
.cart-total-button:hover {
  background-color: #004bff !important;
  border-color: #004bff !important;
}
.cart-product-item-img {
  padding-top: 0;
  width: 344px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}
.container-product-name-size {
  margin-left: 24px;
  margin-top: 42px;
}
.arrow-container {
  background: #f9f9f9;
  border-radius: 5px 5px 5px 5px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid var(--rtsb-quantity-border-color, #e2e2e2);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  max-width: 100%;
}
.arrow-input {
  color: #4a4c53;
  border-radius: 50px 50px 50px 50px;
  height: 41px;
  width: 48px;
  padding: 0.326em;
  background-color: initial;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #a7a7a7;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05rem;
  min-height: auto;
  outline: 0;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
}

.arrow-arrows {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.arrow-up {
  height: calc(41px / 2);
  width: 32px;
  font-size: 12px;
  padding: 5px;
  border: 0;
  position: relative;
}

.arrow-down {
  height: calc(41px / 2);
  border: 0;
  position: relative;
}

/* i::before {
  content: "\f106";
} */

.arrow-up-icon {
  height: 16px;
  width: 16px;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  bottom: 0;
  right: 0;
  color: #a7a7a7;
}

.arrow-up-icon:hover {
  color: #004bff;
}

.cart-product-delete-button {
  color: #000000;
  margin: 0;
  padding: 0;
  text-decoration: none;
  border-bottom: 1px dashed #000;
  -webkit-transition: color 0.1s;
  transition: color 0.1s;
  background: transparent;
  border: transparent;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 38px;
}
.cart-product-delete-button:hover {
  color: #004bff;
}
.cart-hidden-mobile {
  width: 113px;
  border-radius: 13px;
}

.cart-change-cart-container {
  margin-top: 14px;
}
@media only screen and (max-width: 800px) {
  .cart-container {
    flex-direction: column;
  }
  .cart-products {
    width: 100%;
  }
  .cart-pay {
    width: 100%;
    margin-left: 0;
  }
  .cart-hidden-mobile {
    display: none;
  }
  .cart-product-item-img {
    /* padding-top: 50px; */
    width: 100px;
  }
  .cart-product-link {
    margin-left: 0;
  }
  .container-product-name-size {
    margin-left: 0;
    margin-top: 36px;
  }
}
