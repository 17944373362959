.account-details-container {
  width: 68.665%;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e1e1e1;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 30px 30px 30px;
  /* margin-right: 25px; */
}

@media only screen and (max-width: 800px) {
  .account-details-container {
    width: 100%;
  }
}
