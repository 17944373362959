.orders-container {
  width: 68.665%;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e1e1e1;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 30px 30px 30px;
}
.order-action {
  width: 18px;
  color: rgb(0, 102, 255);
  font-weight: 500;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  margin-top: auto;
  bottom: 0;
  margin-bottom: auto;
}

.order-action:hover {
  color: white;
}
.order-processing {
  font-size: 13px;
  font-weight: 500;
  height: 30px;
  justify-content: center;
  line-height: 15px;
  padding: 6px 12px;
  align-items: center;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  max-width: 116px;
  height: auto;
}

.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  /* background-color:  #f3f7fc; */
  background-color: #d9eaffc9;
}

.table-borderless td,
.table-borderless th {
  /* border: 0;
  background-color: #d9eaffc9; */
}

.order-action-button {
  background: white;
  border-color: rgb(0, 102, 255);
  width: 33px;
  height: 33px;
  position: relative;
}

.order-table-head {
  display: contents;
}

.order-table-content {
  display: contents;
}
.order-td {
  display: table-cell;
}
@media only screen and (max-width: 800px) {
  .orders-container {
    width: 100%;
  }
  .order-table-head {
    display: none;
  }
  .order-table-content {
    /* background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
   
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px; */

    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
  }
  .order-td {
    display: block;
  }
  .order-td::before {
    content: attr(data-title) " ";
    font-weight: 600;
    float: left;
    margin-right: 15px;
  }

  .table-borderless td,
  .table-borderless th {
    border: 0;
    background-color: #d9eaffc9;
  }
}
