.women-container-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 10%;
  margin-right: 10%;
  justify-content: center;
}

.img-container {
  width: 149px;
  border-radius: 9px 9px 0 0;
  height: 15rem;
}
.card-product-container {
  width: 150px;
  cursor: pointer;
}

.my-card {
  width: 150px;
  border: 1px solid #dddddd;
  border-radius: 9px;
  margin: 10px 0 0 10px;
  cursor: pointer;
}
.card-product-price {
  padding-left: 10px;
}
.card-product-name {
  font-size: 18px;
  font-weight: 600;
  padding-left: 10px;
}

.women-head-text {
  margin-top: 50px;
  text-align: center;
  margin-bottom: 34px;
}

@media only screen and (max-width: 800px) {
  .women-container-cards {
    margin-left: 0;
    margin-right: 0;
  }
}
