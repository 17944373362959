.header {
  display: flex;
  margin-top: 10px;
  align-items: center;
  height: 74px;
  border-bottom: 1px solid #bdbdbd2e;
}
.shop-container {
  /* margin-left: auto; */
  margin-right: 3%;
  display: flex;
  width: 100px;
  justify-content: space-around;
}
.menu-items {
  display: flex;
  width: 500px;
  justify-content: space-around;
  /* font-family: fantasy; */
  /* font-size: 1.3rem; */
  margin-left: auto;
  align-items: baseline;
}
.menu-item {
  color: #000719;
  text-decoration: dashed;
  font-family: Inter, sans-serif;
  font-weight: 500;
  cursor: pointer;
}

.menu-item:hover {
  text-decoration: underline;
}

.menu-items-show-hide {
  display: flex;
  justify-content: space-evenly;
  width: 54px;
  display: none;
  cursor: pointer;
}

.button-dropdown-menu {
  margin-top: 4px;
  height: 70px;
  font-size: 18px !important;
}

.container-dropdown-menu {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.header-icons {
  color: #000719;
  cursor: pointer;
}
.header-icons:hover {
  color: #0d6efd;
}

.heaeder-badge {
  position: absolute;
  font-size: 9px !important;
}

.header-cart-container {
  cursor: pointer;
  position: relative;
}
.header-cart-container:hover .heaeder-badge {
  background-color: #0d6efd !important;
}

@media only screen and (max-width: 600px) {
  .menu-items {
    /* background-color: red; */
    display: none;
  }
  .menu-items-show-hide {
    display: contents;
  }
  .my-class {
    width: 75% !important;
  }
  .shop-container {
    margin-left: auto;
    margin-right: 25px;
  }
  .header-cart-container {
    margin-right: 16px;
  }
}

body {
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
}
