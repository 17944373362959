.product-details-color-picker {
  width: 30px;
  height: 30px;
  /* box-shadow: 0 0 0 2px #000000 !important; */
  background-color: #ffffff !important;
  border-radius: 100%;
  cursor: pointer;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) !important;
}
.product-details-color-inside {
  /* background-color: #68242e; */
  height: 100%;
  position: relative;
  width: 100%;
  display: block;
  border-radius: 100%;
}
.product-details-color-picker-disabled {
  width: 30px;
  height: 30px;
  /* box-shadow: 0 0 0 2px #000000 !important; */
  background-color: #ffffff !important;
  border-radius: 100%;
  cursor: none;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) !important;
}
.product-details-color-picker-active {
  width: 30px;
  height: 30px;
  /* box-shadow: 0 0 0 2px #000000 !important; */
  background-color: #ffffff !important;
  border-radius: 100%;
  cursor: pointer;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 2px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 1) !important;
}
