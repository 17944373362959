.product-details-container {
  display: flex;
  margin-top: 44px;
  /* margin-left: 52px; */
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: calc(100% - 200px);
}
.product-details-carousel {
  height: fit-content;
  width: 400px;
}
.product-details-image {
  width: 400px;
  border-radius: 16px;
}
.product-details-name {
  font-size: 30px;
  margin-top: 0px;
}
.product-details-price {
  font-size: 22px;
  margin-bottom: 25px;
}
.product-details-2nd-container {
  margin-left: 40px;
  width: 624px;
}
.product-details-description {
  margin-top: 25px;
  margin-bottom: 25px;
}
.product-details-color-container {
  display: flex;
  /* width: 72px; */
  /* justify-content: space-between; */
  gap: 11px;
}
.product-details-generic-title {
  margin-top: 25px;
  margin-bottom: 12px;
}
.product-details-link-history {
  margin-left: 20px;
  margin-top: 20px;
}
.product-details-size-container {
  display: flex;
  /* width: 168px; */
  /* justify-content: space-between; */
  gap: 11px;
}
.product-details-button-plus {
  width: 50px;
  height: 50px;
  border-radius: var(--bs-border-radius) 0 0 var(--bs-border-radius);
}
.product-details-button-minus {
  width: 50px;
  height: 50px;
  border-radius: 0 var(--bs-border-radius) var(--bs-border-radius) 0;
}

.product-details-input {
  height: 50px;
  width: 86px;
  text-align: center;
  border-radius: 0;
}
.product-details-buttons-container {
  display: flex;
  margin-top: 25px;
}

.product-details-add-cart {
  margin-top: 25px;
}

.product-details-buy-button {
  height: 50px;
  width: 189px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 800px) {
  .product-details-container {
    display: flex;
    margin-top: 44px;
    /* margin-left: 52px; */
    margin-left: auto;
    margin-right: auto;
    justify-content: flex-end;
    width: calc(100% - 75px);
    flex-direction: column;
    /* justify-content: center; */
    /* align-content: flex-end; */
    align-items: center;
  }
  .product-details-2nd-container {
    /* width: unset; */
    width: 300px;
    margin-left: 0;
  }
  .product-details-carousel {
    width: 300px;
  }
  .product-details-image {
    width: 300px;
  }
  .product-details-name {
    margin-top: 20px;
  }
}
