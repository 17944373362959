.size-picker-container {
  border-radius: 2px;
  text-align: center;
  width: auto;
  min-width: 30px;
  background-color: #ffffff !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  padding: 2px;
  align-items: center;
  height: 30px;
  cursor: pointer;
}

.size-picker-container-disabled {
  border-radius: 2px;
  text-align: center;
  width: auto;
  min-width: 30px;
  background-color: #ffffff !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  padding: 2px;
  align-items: center;
  height: 30px;
  cursor: none;
  pointer-events: none;
  opacity: 0.4;
}

.size-picker-span {
  padding: 0 5px;
  color: #000000 !important;
  font-size: 16px;
}
.size-picker-container:hover {
  box-shadow: 0 0 0 1px rgb(0, 0, 0);
}
