.footer {
    background-color: #f6f6f6;
    margin-top: 9rem;
    padding-top: 1rem;
    padding-block-end: 1rem;
    border-top: 1px solid #ddd;
}

.footer-text{
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-right: 3rem;
}