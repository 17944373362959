.main-banner-home {
  background-image: url("../../img/main-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  height: 100vh;
  position: relative;
  cursor: pointer;
}

.container-adv {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin: 1rem;
}
.mini-container-adv {
  border-color: #e1e3e5;
  border-width: 1px;
  border-style: solid;
  padding: 2rem;
}

.category-container {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
  gap: 2rem;
}

.men-container {
  /* grid-row: span 2 / span 2; */
  grid-column: span 1 / span 1;
  position: relative;
}

.women-container {
  grid-row: span 1 / span 1;
  grid-column: span 1 / span 1;
  position: relative;
}

.category-img {
  max-width: 100%;
  height: auto;
  cursor: pointer;
  /* height: 100%; */
}

.category-img:hover {
  filter: grayscale(100%);
}

.category-buttons {
  position: absolute;
  top: 50%;
  left: 50%;
  padding-left: 2rem;
  padding-right: 2rem;
  /* background-color: #fff; */
}

.featured-products {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* Media query */

@media only screen and (max-width: 600px) {
  .container-adv {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
@media only screen and (min-width: 1200px) {
  .page-width {
    padding: 0 55px;
    max-width: 1200px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 800px) {
  .main-banner-home {
    background-image: url("../../img/banner_phone.png");
    height: 1040px;
  }
}

@media (min-width: 800px) and (max-width: 1800px) {
  .main-banner-home {
    height: 100vh;
  }
}
