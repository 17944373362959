.account-container {
  display: flex;
  margin-top: 25px;
  margin-right: 25px;
  margin-left: 25px;
}
.account-left-panel {
  width: 28%;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e1e1e1;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 30px 30px 30px;
  /* margin-left: 25px; */
  margin-right: 25px;
  height: fit-content;
}
.account-svg {
  height: 21px;
  width: 21px;
  margin-right: 12px;
}
.account-left-panel-item {
  display: flex;
  align-items: center;
  border: 0;
  height: 46px;
  color: #5b5d63;
  font-weight: 500;
}
.account-left-panel-item:hover {
  color: #004bff;
  background: #f4f6ff;
}
.list-group-item.active {
  z-index: 2;
  color: #004bff;
  background: #f4f6ff;
}

@media only screen and (max-width: 800px) {
  .account-container {
    display: block;
  }
  .account-left-panel {
    width: 100%;
    margin-bottom: 25px;
  }
}
