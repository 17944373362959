.checkout-left {
  width: 60%;
}
.checkout-right {
  width: 40%;
  margin-left: 50px;
  margin-bottom: 50px;
}

.checkout-title {
  font-size: 20px;
  font-weight: 500;
  color: #000719 !important;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #e4e4e4;
  /* margin: 0px 0px 25px 0px !important; */
  padding: 0px 0px 10px 0px !important;
}

.checkout-table {
  width: 100%;
}

.checkout-table-item {
  font-weight: 600;
  color: #000719 !important;
  padding: 15px 0px 15px 0px !important;
  background-color: #ffffff;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #e4e4e4;
}
.checkout-total-order {
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-color: #d7d7d7;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10196078431372549);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 30px 30px 30px;
}

.checkout-table-subitem {
  font-weight: 400;
}

.checkout-toast {
  background-color: #09a906d6;
  font-weight: 500;
}
@media only screen and (max-width: 800px) {
  .checkout-left {
    width: 100%;
    /* margin-left: 20px; */
  }
  .checkout-right {
    width: 100%;
    margin-left: 0px;
    margin-top: 50px;
  }
}
