.addresses-container {
  width: 68.665%;
  border-radius: 10px 10px 10px 10px;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #e1e1e1;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 30px 30px 30px 30px;
}
.addresses-form-container {
  margin-top: 25px;
}

.addresses-cards-container {
  display: flex;
  grid-gap: 20px;
  margin-top: 25px;
}

.addresses-card {
  width: calc(50% - 10px);
  border: 1px solid #eeeeee;
  border-radius: 12px;
  padding: 20px;
}
.addresses-card-content-text-header {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  font-size: 16px;
  font-weight: 600;
}

.addresses-card-content {
  margin-left: 25px;
}
.addresses-card-content-text-addr {
  height: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  margin-top: 10px;
  color: #888888 !important;
}

.addresses-card-svg {
  width: 77px;
  height: 47px;
}

.addresses-card-content-actions {
  display: flex;
  justify-content: space-between;
  width: 144px;
  text-decoration: underline;
  cursor: pointer;
}
.addresses-card-content-action:hover {
  color: #0d6efd;
}

@media only screen and (max-width: 800px) {
  .addresses-container {
    width: 100%;
  }
  .addresses-cards-container {
    display: block;
  }
  .addresses-card {
    width: 100%;
    margin-bottom: 25px;
  }
  .addresses-card-content {
    margin-left: 10px;
  }
  .addresses-card-svg {
    width: 55px;
    height: 42px;
  }
}
