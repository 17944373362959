.image-404{
    background-image: url("../../img/c52164749f7460c1ededf8992cd9a6ec.jpg");
    background-size: contain;
    background-repeat: repeat;
    background-position: center;
    height: 426px;
}
.rest-of-the-space-404{
    height: 15rem;
    background: #bbece6;
}
.placeholder-404{
    background: #a7d3e6;
    height: 10rem;
}
.container-404{
    display: flex;
    flex-direction: column;
}