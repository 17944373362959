.card-container {
  padding-right: 16px;
  padding-left: 16px;
  flex-basis: 25%;
  max-width: 20%;
  position: relative;
  width: 50%;
}

.card-image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
  /* width: 280px;
  height: 373px; */
  /* 280 × 373 px */
  border-radius: 16px;
}
.card-middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.card-text {
  background-color: #04aa6d;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.card-container:hover .card-image {
  opacity: 0.3;
}

.card-container:hover .card-middle {
  opacity: 1;
}

.card-container:hover .card-down-link-text {
  color: blue;
}

.card-down-container {
  padding: 24px 16px;
  text-align: center;
}
.card-down-link-text {
  transition: color 200ms;
  color: #000000;
  font-size: 1.1rem;
}
.card-link {
  text-decoration: none;
  cursor: pointer;
  counter-reset: 000000;
}

.card-down-link-price {
  transition: color 200ms;
  color: #000000;
  font-size: 0.6rem;
}

@media only screen and (max-width: 800px) {
  .card-container {
    padding-right: 16px;
    padding-left: 16px;
    flex-basis: 50%;
    max-width: 50%;
    position: relative;
    width: 50%;
  }
}

@media (min-width: 800px) and (max-width: 1800px) {
  .card-container {
    padding-right: 16px;
    padding-left: 16px;
    flex-basis: 25%;
    max-width: 25%;
    position: relative;
    width: 50%;
  }
}
